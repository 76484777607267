import React from 'react';
import {Button, FormControl, Grid, Paper, TextField, Typography, Link} from '@mui/material';
import {FCreateRoom, UseRoom} from './useRoom';
import {UIConfig} from './message';
import {randomRoomName} from './name';
import {authModeToRoomMode, UseConfig} from './useConfig';
import {LoginForm} from './LoginForm';

const CreateRoom = ({room, config}: Pick<UseRoom, 'room'> & {config: UIConfig}) => {
    const [label, setLabel] = React.useState('');
    const mode = authModeToRoomMode(config.authMode, config.loggedIn);
    const id = randomRoomName();

    const submit = () =>
        room({
            type: 'create',
            payload: {
                mode,
                closeOnOwnerLeave: true,
                id: id || undefined,
                label: label,
            },
        });

    return (
        <div>
            <FormControl fullWidth>
                <TextField
                    fullWidth
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                    label="Naam"
                    margin="dense"
                />

                <Button onClick={submit} fullWidth variant="contained">
                    Ruimte maken
                </Button>
            </FormControl>
        </div>
    );
};

export const RoomManage = ({room, config}: {room: FCreateRoom; config: UseConfig}) => {
    const [showLogin, setShowLogin] = React.useState(false);

    const canCreateRoom = config.authMode !== 'all';
    const loginVisible = !config.loggedIn && (showLogin || !canCreateRoom);

    return (
        <Grid
            container={true}
            justifyContent="center"
            style={{paddingTop: 50, maxWidth: 400, width: '100%', margin: '0 auto'}}
            spacing={4}
        >
            <Grid item xs={12}>
                <Typography align="center" gutterBottom>
                    <img src="./logo.webp" style={{width: 230}} alt="logo" />
                </Typography>
                <Paper elevation={3} style={{padding: 20}}>
                    {loginVisible ? (
                        <LoginForm
                            config={config}
                            hide={canCreateRoom ? () => setShowLogin(false) : undefined}
                        />
                    ) : (
                        <>
                            <Typography style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{flex: 1}}>Hallo {config.user}!</span>{' '}
                                {config.loggedIn ? (
                                    <Button variant="outlined" size="small" onClick={config.logout}>
                                        Uitloggen
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setShowLogin(true)}
                                    >
                                        Inloggen
                                    </Button>
                                )}
                            </Typography>

                            <CreateRoom room={room} config={config} />
                        </>
                    )}
                </Paper>
            </Grid>
            <div style={{position: 'absolute', margin: '0 auto', bottom: 0}}>Mobilox Demo</div>
        </Grid>
    );
};
